@import './app-variables';

.mat-button-wrapper {
  text-transform: uppercase;
}

.mat-input-element {
  font-size: $base-font-size-web !important;
}

mat-dialog-container.mat-dialog-container {
  padding: 24px;
}

.mat-dialog-content {
  div,
  span,
  p {
    color: $foundational-color-7;
    font-size: 14px;
  }

  .mat-input-element,
  .mat-select-value-text * {
    color: $foundational-color-10;
    font-size: $base-font-size-web;
  }
  .mat-error span,
  .mat-error div,
  .mat-error p {
    font-size: $text-size-body-4;
    font-weight: $text-weight-rg;
    color: $text-color-error;
  }
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  // background-image: linear-gradient(
  //   to right,
  //   rgba(0, 0, 0, 0.42) 0%,
  //   rgba(0, 0, 0, 0.42) 100%,
  //   transparent 0%
  // );
  background-image: none;
  background-size: unset;
  background-repeat: no-repeat;
}

.mat-card {
  padding: 0 !important;
}

.mat-card-header-text {
  width: 100%;
}
.mat-expansion-panel-header.mat-expanded {
  background: white !important;
}
.mat-expansion-panel-header {
  padding: 0 16px !important;
  &.mat-expanded {
    background: $app-background;
  }

  .mat-expansion-panel-header-title {
    flex-grow: 1 !important;
    display: flex !important;
  }
  max-height: 48px;
}

.mat-expansion-panel-body {
  border-top: $foundational-color-4 solid 1px;
  padding: 0 !important;
}

.mat-list-item-content {
  padding: 0 !important;
}

.mat-dialog-content {
  padding: 12px 0 !important;
  overflow: hidden !important;
}

.mat-dialog-actions {
  justify-content: flex-end !important;
  margin: 0 !important;
  padding: 0 !important;
}

.mat-form-field {
  margin-bottom: 0 !important;
}
mat-selection-list {
  padding: 0 !important;
}

.mat-list-item {
  border-top: solid 1px;
  border-color: $divider-color;
}

// mat-list-option:last-child {
//   border-bottom: solid 1px $divider-color;
//   margin-bottom: 2px;
// }

mat-list-item:hover {
  cursor: pointer;
}

.mat-list-text {
  font-size: 14px !important;
}

mat-pseudo-checkbox {
  display: none !important;
}

.mat-list-option:hover,
.mat-list-option.mat-list-item-focus {
  background-color: $highlight-color;
}

mat-checkbox {
  font-size: $text-size-body-3;
}

.mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: $action-color-1;
}

mat-form-field {
  font-size: $text-size-body-3 !important;
}

.mat-autocomplete-panel {
  .mat-option-text {
    font-size: $text-size-body-3;
  }
}

.mat-select-content {
  mat-option {
    font-size: 14px !important;
  }
}

mat-icon {
  color: $text-color-dark-4;
}
.mat-input-element:disabled {
  color: unset !important;
  background-color: unset !important;
}

.mat-menu-content > .mat-menu-item {
  font-size: 14px;
}

.mat-hint {
  font-size: 9px;
  padding-top: 4px;
}

.mat-expansion-panel-body {
  min-height: 48px;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) {
  .mat-slide-toggle-thumb {
    background-color: $slide-toggle-1;
  }

  .mat-slide-toggle-bar {
    background-color: $slide-toggle-2;
  }
}

.mat-expansion-indicator {
  padding-bottom: 9px;
}

.mat-form-field-label {
  font-size: 0.785rem !important;
}

.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-form-field-wrapper .mat-form-field-infix,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-form-field-wrapper .mat-form-field-infix {
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 2px;
  padding: 6px 0px;
}

mat-form-field {
  &.mat-form-field-type-mat-input {
    &.mat-form-field-appearance-legacy.mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
      left: 0px;
    }
    &:not(.mat-form-field-has-label) {
      padding-top: 24px;
    }
    & .mat-form-field-flex .mat-form-field-infix .mat-input-element {
      padding: 6px 0px;
    }
  }

  & .mat-error {
    font-size: 0.75rem !important;
  }

  & .mat-form-field-flex {
    border: none !important;
    border-bottom: 1px solid #d8d8d8 !important;
  }
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
  margin-left: 4px;
}
