@import '@angular/material/theming';
// Plus imports for other components in your app.

$xpo-ltl-primary-palette: (
  50: #ffffff,
  100: #8fadff,
  200: #759aff,
  300: #03a9f4,
  400: #0091ea,
  500: #0091ea,
  600: #0373b5,
  700: #0277bd,
  800: #025b8e,
  900: #333333,
  A100: #80d8ff,
  A200: #dbe5ff,
  A400: #f5f8ff,
  A700: #0289f7,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  ),
);
$xpo-ltl-accent-palette: (
  50: #ffffff,
  100: #002da8,
  200: #0034c2,
  300: #003adb,
  400: #0041f5,
  500: #0f4fff,
  600: #4275ff,
  700: #5c87ff,
  800: #759aff,
  900: #8fadff,
  A100: #0091ea,
  A200: #0091ea,
  A400: #0091ea,
  A700: #a8c0ff,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: $white-87-opacity,
    A100: white,
    A200: white,
    A400: $black-87-opacity,
    A700: white,
  ),
);
// $black-74-opacity: rgba(0, 0, 0, 0.74);
// $black-74-opacity: #0091EA;
$xpo-ltl-dark-primary-palette: (
  50: #ffffff,
  100: black,
  200: #212121,
  300: #212121,
  400: rgba(0, 145, 234, 0.74),
  500: rgba(0, 145, 234, 1),
  600: #424242,
  700: #212121,
  800: #757575,
  900: #9e9e9e,
  A100: #0091ea,
  A200: #0091ea,
  A400: #0091ea,
  A700: #a8c0ff,
  contrast: (
    50: #0091ea,
    100: #0091ea,
    200: #0091ea,
    300: #0091ea,
    400: #0091ea,
    500: #ffffff,
    600: #0091ea,
    700: #0091ea,
    800: #0091ea,
    900: #0091ea,
    A100: #0091ea,
    A200: #0091ea,
    A400: #0091ea,
    A700: #0091ea,
  ),
);

$xpo-ltl-warn-palette: (
  50: #fae0e0,
  100: #f2b3b3,
  200: #ea8080,
  300: #e24d4d,
  400: #db2626,
  500: #d50000,
  600: #d00000,
  700: #ca0000,
  800: #c40000,
  900: #ba0000,
  A100: #ffe3e3,
  A200: #ffb0b0,
  A400: #ff7d7d,
  A700: #ff6363,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$xpo-ltl-app-primary: mat-palette($xpo-ltl-primary-palette);
$xpo-ltl-app-accent: mat-palette($xpo-ltl-accent-palette, A200, A100, A400);
$xpo-ltl-app-warn: mat-palette($xpo-ltl-warn-palette);
// Create the theme object (a Sass map containing all of the palettes).
$xpo-ltl-app-theme: mat-light-theme($xpo-ltl-app-primary, $xpo-ltl-app-accent, $xpo-ltl-app-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($xpo-ltl-app-theme);
// Dark Theme
$xpo-ltl-dark-theme-primary: mat-palette($xpo-ltl-dark-primary-palette);
$xpo-ltl-dark-theme-accent: mat-palette($xpo-ltl-dark-primary-palette, A200, A100, A400);
$xpo-ltl-app-dark-theme: mat-dark-theme($xpo-ltl-dark-theme-primary, $xpo-ltl-dark-theme-accent, $xpo-ltl-app-warn);
.xpo-ltl-dark-theme {
  @include angular-material-theme($xpo-ltl-app-dark-theme);
  .mat-input-placeholder {
    color: rgba(255, 255, 255, 0.54) !important;
  }
}
