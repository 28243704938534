/* You can add global styles to this file, and also import other style files */

@import 'app-variables';

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: $base-font-family;
  font-size: $base-font-size-web;
}

a {
  color: $action-color-1;
  text-decoration: none;
}
form {
  .read-only-field {
    &__container {
      padding: 0 4px;
      margin-top: -4px;
    }

    &__label {
      margin-bottom: 8px;
      color: $foundational-color-7;
      font-size: 12px;
    }

    &__value {
      // use if needed
    }
  }
}
